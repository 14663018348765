import React, { useState, useEffect } from "react";
//import { useNavigate } from 'react-router-dom';

import "./Hero.css";
import MuscularMan from "../../assets/male.png";
import SportyWoman from "../../assets/female.png";
import VeryMuscularMan from "../../assets/muscular-man.png";
import LeanMuscularMan from "../../assets/lean-man.png";
import MuscularWomen from "../../assets/muscular-woman.png";
import FitWoman from "../../assets/fit-woman.png";
import UserDetailsForm from "./UserDetailsForm";
import ActivityLevelSelector from "./ActivityLevelSelector";
import calculateCalories from "./CalculateCalories";
import { Link } from "react-router-dom";

const Hero = () => {
  const [goal, setGoal] = useState(null);
  const [calculatedCalories, setCalculatedCalories] = useState(null);
  const [isCalorieCalculationDone, setIsCalorieCalculationDone] =
    useState(false);
  const [subGoalDetailsVisible, setSubGoalDetailsVisible] = useState(false);
  const [isFinalStage, setIsFinalStage] = useState(false);

  const [userChoices, setUserChoices] = useState({
    gender: null,
    goal: null,
    subGoal: null,
    age: "",
    height: "",
    weight: "",
  });
  const [isActivityLevelVisible, setIsActivityLevelVisible] = useState(false);
  //const navigate = useNavigate();

  const handleChoice = (gender, goal) => {
    setUserChoices({ ...userChoices, gender, goal });
    setGoal(goal);
    setSubGoalDetailsVisible(false);
  };

  const handleSubGoalChoice = (subGoal) => {
    setUserChoices({ ...userChoices, subGoal });
    setSubGoalDetailsVisible(true);
  };

  const handleUserDetailsSubmit = () => {
    // Adatok mentése, validálása, stb.
    setIsActivityLevelVisible(true);
  };

  const handleActivityLevelSelect = (activityLevel) => {
    setUserChoices({ ...userChoices, activityLevel });
    setIsActivityLevelVisible(false);
    const calories = calculateCalories({ ...userChoices, activityLevel });
    setCalculatedCalories(calories);
    setIsCalorieCalculationDone(true);
    setIsFinalStage(true);
  };

  const handleContinue = () => {
    const { totalDailyCalories, goalCalories } = calculateCalories(userChoices);
    setCalculatedCalories({ totalDailyCalories, goalCalories });
    setIsCalorieCalculationDone(true);
    setIsFinalStage(true);
  };

  const handleBack = () => {
    setSubGoalDetailsVisible(false);
    setIsFinalStage(false);
  };

  const handleBackToForm = () => {
    setIsActivityLevelVisible(false);
    setIsCalorieCalculationDone(false);
    setIsFinalStage(false);
  };

  const handleBackToActivityLevel = () => {
    setIsCalorieCalculationDone(false);
    setIsActivityLevelVisible(true);
    setIsFinalStage(false);
  };

  const backToHome = () => {
    <Link to="/"></Link>;
  };

  const handleLogoClick = () => {
    setGoal(null); // A cél állapotának alaphelyzetbe állítása
    setSubGoalDetailsVisible(false); // Az al-cél részleteinek elrejtése
    setIsActivityLevelVisible(false);
    setIsCalorieCalculationDone(false);
    setUserChoices({
      // A felhasználói választások alaphelyzetbe állítása
      gender: null,
      goal: null,
      subGoal: null,
      age: "",
      height: "",
      weight: "",
    });
    <Link to="/"></Link>;
  };

  // Aktivitási szintek fordítása (Adatok megjelenítésénél fontos)
  const activityLevelTranslations = {
    sedentary: "Enyhe (keveset vagy egyáltalán nem mozog)",
    lightly_active: "Enyhén aktív (könnyű testmozgás/sport heti 3-5 nap)",
    moderately_active:
      "Közepesen aktív (mérsékelt testmozgás/sport heti 3-5 nap)",
    very_active: "Nagyon aktív (Kemény edzés/sport heti 6-7 napon)",
    extra_active:
      "Extra aktív (Kemény edzés/sport heti 6-7 napon, plusz fizikai munka)",
  };

  // A felhasználó által választott aktivitási szint lekérdezése
  const activityLevelText =
    activityLevelTranslations[userChoices.activityLevel];

  // Cél fordítása (Adatok megjelenítésénél fontos)
  const subGoalTranslation = {
    muscleBuilding: "Tömegnövelés",
    weightLoss: "Súly leadás",
  };

  // A felhasználó által választott aktivitási szint lekérdezése
  const subGoalText = subGoalTranslation[userChoices.subGoal];

  useEffect(() => {
    console.log("Felhasználói választások:", userChoices);
  }, [userChoices]);

  return (
    <div
      className="hero bg-image"
    >
      <div className="hero-content">
        {!goal && !isActivityLevelVisible && !isCalorieCalculationDone && (
          <>
            <h1>Építsd meg álmaid testét</h1>
            <h3>Válaszd ki a nemed</h3>
            <div className="hero-products">
              <div
                className="hero-product"
                style={{ backgroundImage: `url(${MuscularMan})` }}
                onClick={() => handleChoice("male", "build")}
              ></div>
              <div
                className="hero-product"
                style={{ backgroundImage: `url(${SportyWoman})` }}
                onClick={() => handleChoice("female", "tone")}
              ></div>
            </div>
            <div className="buttonpos">
              <button>
                <Link to="/">Kezdőlap</Link>
              </button>
            </div>
          </>
        )}
        {goal &&
          !subGoalDetailsVisible &&
          !isActivityLevelVisible &&
          !isCalorieCalculationDone && (
            <>
              <div className="buttonpos">
                <button onClick={() => setGoal(null)}>Vissza</button>
                <br />
                <br />
              </div>
              <h1>Válaszd ki a célodat</h1>
              <div className="hero-products">
                <div
                  className="hero-product"
                  style={{
                    backgroundImage: `url(${
                      goal === "build" ? VeryMuscularMan : MuscularWomen
                    })`,
                  }}
                  onClick={() => handleSubGoalChoice("muscleBuilding")}
                >
                  <div className="hero-product-text">Izomépítés</div>
                </div>
                <div
                  className="hero-product"
                  style={{
                    backgroundImage: `url(${
                      goal === "build" ? LeanMuscularMan : FitWoman
                    })`,
                  }}
                  onClick={() => handleSubGoalChoice("weightLoss")}
                >
                  <div className="hero-product-text">Súly leadás</div>
                </div>
              </div>
            </>
          )}
        {subGoalDetailsVisible &&
          !isActivityLevelVisible &&
          !isCalorieCalculationDone && (
            <UserDetailsForm
              userChoices={userChoices}
              setUserChoices={setUserChoices}
              onSubmit={handleUserDetailsSubmit} // Ez kezeli a form beküldését
              onBack={handleBack} // Ezzel visszalépsz a cél kiválasztásához
            />
          )}
        {isActivityLevelVisible && !isCalorieCalculationDone && (
          <ActivityLevelSelector
            onActivityLevelSelect={handleActivityLevelSelect}
            onBack2={handleBackToForm} // "Vissza" gomb eseménykezelője a formhoz
            onContinue={handleContinue}
          />
        )}
        {isFinalStage && (
          <div className="user-details">
            <h3>Adatok:</h3>
            <ul>
              <li>Nem: {userChoices.gender === "male" ? "Férfi" : "Nő"}</li>
              <li>Kor: {userChoices.age} év</li>
              <li>Magasság: {userChoices.height} cm</li>
              <li>Súly: {userChoices.weight} kg</li>
              <li>Cél: {subGoalText}</li>
              <li>Aktivitási szint: {activityLevelText}</li>
            </ul>
            <div>
              <h3>
                Teljes napi energiafelhasználásod:{" "}
                <span style={{ color: "#167cac" }}>
                  {calculatedCalories.totalDailyCalories}
                </span>{" "}
                kcal
              </h3>
              <h3>
                A célod eléréséhez szükséges kalóriabevitel:{" "}
                <span style={{ color: "#167cac" }}>
                  {calculatedCalories.goalCalories}
                </span>{" "}
                kcal
              </h3>
            </div>
            <button onClick={handleBackToActivityLevel}>Vissza</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Hero;
