import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { React, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Rating from "../components/ui/Rating";
import Price from "../components/ui/Price";
import Plan from "../components/ui/Plan";

const PlanInfo = ({ workoutPlans, addToCart, cart }) => {
  const { id } = useParams();
  /* +-jel numberré konvertálja */
  const plan = workoutPlans.find((plan) => +plan.id === +id);

  function addPlanToCart(plan) {
    addToCart(plan);
  }

  function planExistsOnCart() {
    const dupe = cart.find((plan) => plan.id === +id);
    return dupe;
  }

  return (
    <div id="plans__body">
      <main id="plans__main">
        <div className="plans__container">
          <div className="row">
            <div className="plan__selected--top">
              <Link to="/plans" className="plan__link">
                <FontAwesomeIcon icon="arrow-left" />
              </Link>
              <Link to="/plans" className="plan__link">
                <h2 className="plan__selected--title--top">Edzéstervek</h2>
              </Link>
            </div>
            <div className="plan__selected">
              <figure className="plan__selected--figure">
                <img src={plan.url} alt="" className="plan__selected--img" />
              </figure>
              <div className="plan__selected--description">
                <h2 className="plan__selected--title">{plan.title}</h2>
                <Rating rating={plan.rating} />
                <div className="plan__selected--price">
                  <Price
                    originalPrice={plan.originalPrice}
                    salePrice={plan.salePrice}
                  />
                </div>
                <div className="plan__summary">
                  <h3 className="plan__summary--title">Összefoglalás</h3>
                  <p className="plan__summary--para">
                    Egy jól megtervezett
                    edzésterv segít meghatározni a célokat, nyomon követni a
                    haladást és fenntartani a motivációt. Az edzésterveink
                    különféle fitness szintekre és célokra szabva kínálnak
                    strukturált útmutatást, legyen szó súlycsökkentésről,
                    erőnövelésről vagy általános kondíció javításáról. 
                  </p>
                  <p className="plan__summary--para">
                     Minden tervet úgy alakítunk ki, hogy az
                    illeszkedjen az egyéni igényeidhez, figyelembe véve
                    jelenlegi fizikai állapotodat és céljaidat. Rendszeres
                    használatukkal nem csak közelebb kerülhetsz a céljaidhoz, de
                    élvezetesebbé és hatékonyabbá teheted az edzéseidet, mindennapjaidat is.
                  </p>
                </div>
                {planExistsOnCart() ? (
                  <Link to={`/cart`} className="plan__link">
                    <button className="btn">Pénztár</button>
                  </Link>
                ) : (
                  <button className="btn" onClick={() => addPlanToCart(plan)}>
                    Kosárba
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="plans__container">
          <div className="row">
            <div className="plan__selected--top">
              <h2 className="plan__selected--title--top">
                Ajánlott edzéstervek
              </h2>
            </div>
            <div className="plans">
              {workoutPlans
                .filter((plan) => plan.rating === 5 && +plan.id !== +id)
                .slice(0, 4)
                .map((plan) => (
                  <Plan plan={plan} key={plan.id} />
                ))}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default PlanInfo;
