import React from 'react'
import Plan from './ui/Plan';
import { workoutPlans } from '../data/data'


const Featured = () => {
    console.log(workoutPlans);
    console.log(workoutPlans.filter((plan) => plan.rating === 5).slice(0, 4));
    function getFiveStarPlans() {}    
    return (
    <section id="features">
        <div className="container">
            <div className="row">
                <h2 className="section__title">
                    Kiemelt <span className="blue">Edzéstervek</span>
                </h2>
                <div className="plans">
                    {workoutPlans
                        .filter((plan) => plan.rating === 5)
                        .slice(0, 4)
                        .map((plan) => (
                        <Plan plan={plan} key={plan.id} /> 
                        ))}
                </div>
            </div>
        </div>
    </section>
  );
};

export default Featured