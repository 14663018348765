// Importáljuk a képeket
import workoutPlan from '../assets/product_pic_wp.png';
import mealPlan from '../assets/meal_plan.png';


const workoutPlans = [
    // 3 napos edzéstervek
    { id: 1, title: "Férfi 3 napos terv Kezdő", url: workoutPlan, originalPrice: 10000, salePrice: 8000, rating: 5 },
    { id: 2, title: "Férfi 3 napos terv Haladó", url: mealPlan, originalPrice: 12000, salePrice: 39545, rating: 4.7 },
    { id: 3, title: "Női 3 napos terv Kezdő", url: workoutPlan, originalPrice: 12000, salePrice: null, rating: 5 },
    { id: 4, title: "Női 3 napos terv Haladó", url: workoutPlan, originalPrice: 12000, salePrice: 10000, rating: 4.7 },
    { id: 5, title: "3 Napos Kardió kezdő", url: workoutPlan, originalPrice: 12000, salePrice: 10000, rating: 4.7 },
    { id: 6, title: "3 Napos Kardió haladó", url: workoutPlan, originalPrice: 12000, salePrice: 10000, rating: 4.7 },
    { id: 7, title: "3 napos Erőemelő terv", url: workoutPlan, originalPrice: 12000, salePrice: 10000, rating: 4.7 },

    // 4 napos edzéstervek
    { id: 8, title: "Férfi 4 napos terv Kezdő", url: workoutPlan, originalPrice: 11000, salePrice: 9000, rating: 4.4 },
    { id: 9, title: "Férfi 4 napos terv Haladó", url: workoutPlan, originalPrice: 13000, salePrice: 11000, rating: 5 },
    { id: 10, title: "Női 4 napos terv Kezdő", url: workoutPlan, originalPrice: 12000, salePrice: 10000, rating: 4.7 },
    { id: 11, title: "Női 4 napos terv Haladó", url: workoutPlan, originalPrice: 12000, salePrice: 10000, rating: 4.7 },
    { id: 12, title: "4 Napos Kardió kezdő", url: workoutPlan, originalPrice: 12000, salePrice: 10000, rating: 5 },
    { id: 13, title: "4 Napos Kardió haladó", url: workoutPlan, originalPrice: 12000, salePrice: 10000, rating: 4.7 },
    { id: 14, title: "4 napos Erőemelő terv", url: workoutPlan, originalPrice: 12000, salePrice: 10000, rating: 5 },

    // 5 napos edzéstervek
    { id: 15, title: "Férfi 5 napos terv Kezdő", url: workoutPlan, originalPrice: 12000, salePrice: 10000, rating: 4.3 },
    { id: 16, title: "Férfi 5 napos terv Haladó", url: workoutPlan, originalPrice: 14000, salePrice: 12000, rating: 4.8 },
    { id: 17, title: "Női 5 napos terv Kezdő", url: workoutPlan, originalPrice: 12000, salePrice: 10000, rating: 4.7 },
    { id: 18, title: "Női 5 napos terv Haladó", url: workoutPlan, originalPrice: 12000, salePrice: 10000, rating: 4.7 },
    { id: 19, title: "5 Napos Kardió kezdő", url: workoutPlan, originalPrice: 12000, salePrice: 10000, rating: 4.7 },
    { id: 20, title: "5 Napos Kardió haladó", url: workoutPlan, originalPrice: 12000, salePrice: 10000, rating: 4.7 },
    { id: 21, title: "5 napos Erőemelő terv", url: workoutPlan, originalPrice: 12000, salePrice: 10000, rating: 4.7 },

    // 6 napos edzéstervek
    { id: 22, title: "Férfi 6 napos terv Kezdő", url: workoutPlan, originalPrice: 13000, salePrice: 11000, rating: 4 },
    { id: 23, title: "Férfi 6 napos terv Haladó", url: workoutPlan, originalPrice: 15000, salePrice: 13000, rating: 5 },
    { id: 24, title: "Női 6 napos terv Kezdő", url: workoutPlan, originalPrice: 12000, salePrice: 10000, rating: 4.7 },
    { id: 25, title: "Női 6 napos terv Haladó", url: workoutPlan, originalPrice: 12000, salePrice: 10000, rating: 4.7 },
    { id: 26, title: "6 Napos Kardió kezdő", url: workoutPlan, originalPrice: 12000, salePrice: 10000, rating: 4.7 },
    { id: 27, title: "6 Napos Kardió haladó", url: workoutPlan, originalPrice: 12000, salePrice: 10000, rating: 4.7 },
    { id: 28, title: "6 napos Erőemelő terv", url: workoutPlan, originalPrice: 12000, salePrice: 10000, rating: 4.7 }
];

const mealPlans = [
    { id: 29, title: "1500-1800kcal Étrend", url: mealPlan, originalPrice: 5000, salePrice: 4000, rating: 4.8 },
    { id: 30, title: "1900-2200kcal Étrend", url: mealPlan, originalPrice: 5500, salePrice: 4500, rating: 4.7 },
    { id: 31, title: "2300-2600kcal Étrend", url: mealPlan, originalPrice: 6000, salePrice: 5000, rating: 5 },
    { id: 32, title: "2700-3000kcal Étrend", url: mealPlan, originalPrice: 6500, salePrice: 5500, rating: 5 },
    { id: 33, title: "3100-3400 Étrend", url: mealPlan, originalPrice: 6500, salePrice: 5500, rating: 5 },
    { id: 34, title: "3500-3800 Étrend", url: mealPlan, originalPrice: 6500, salePrice: 5500, rating: 5 },
    { id: 35, title: "3900-4100 Étrend", url: mealPlan, originalPrice: 6500, salePrice: 5500, rating: 4.8 },
    { id: 36, title: "4200-4500kcal Étrend", url: mealPlan, originalPrice: 8500, salePrice: 7500, rating: 4.8 }
];

export { workoutPlans, mealPlans };
