import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Rating from "./Rating";
import Price from "./Price";

const Mealplan = ({ plan }) => {
  const [img, setImg] = useState(null);

  const mountedRef = useRef(true);

  useEffect(() => {
    const image = new Image();
    image.onload = () => {
      setTimeout(() => {
        if (mountedRef.current) {
          setImg(image);
        }
      }, 300);
    };
    image.src = plan.url;

    return () => {
      mountedRef.current = false;
    };
  }, [plan.url]);

  return (
    <div className="plan">
      {img ? (
        <>
          <Link to={`/mealplans/${plan.id}`}>
            <figure className="plan__img--wrapper">
              <img src={img.src} alt="" className="plan__img" />
            </figure>
          </Link>
          <div className="plan__title">
            <Link to={`/mealplans/${plan.id}`} className="plan__title--link">
              {plan.title}
            </Link>
          </div>
          <Rating rating={plan.rating} />
          <Price
            salePrice={plan.salePrice}
            originalPrice={plan.originalPrice}
          />
        </>
      ) : (
        <>
          <div className="plan__img--skeleton"></div>
          <div className="skeleton plan__title--skeleton"></div>
          <div className="skeleton plan__rating--skeleton"></div>
          <div className="skeleton plan__price--skeleton"></div>
        </>
      )}
    </div>
  );
};

export default Mealplan;
