import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import Highlight from './ui/Highlight'

const Highlights = () => {
  return (
    <section id="highlights">
        <div className="container">
            <div className="row">
                <h2 className="section__title">
                    Miért válassz <span className="blue">Minket</span>
                </h2>
                <div className="highlight__wrapper">
                    <Highlight 
                    icon={<FontAwesomeIcon icon="bolt"/>} 
                    title="Könnyű és Gyors"
                    para="Vásárlás után 24 órán belül az emaileden a rendelt terv"
                    />
                    <Highlight 
                    icon={<FontAwesomeIcon icon="dumbbell"/>} 
                    title="500+ Terv készítve"
                    para="Tapasztalt csapatunk kizárólag minőségi terveket készít"
                    />
                    <Highlight 
                    icon={<FontAwesomeIcon icon="tags"/>} 
                    title="Megfizethető"
                    para="Mindenki számára elérhető, költséghatékony megoldás"
                    />
                </div>
            </div>
        </div>
    </section>
  )
}

export default Highlights