import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useTotalAmount } from "../../components/TotalAmountContext";

function CheckoutForm() {
  const location = useLocation();
  const { totalAmount } = useTotalAmount();
  const stripe = useStripe();
  const elements = useElements();
  const [clientSecret, setClientSecret] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(""); 
  const [billingDetails, setBillingDetails] = useState({
    name: "",
    email: "",
    address: "",
    phone: "",
  });

  useEffect(() => {
    console.log("Total amount for payment:", totalAmount);
    if (totalAmount > 0) {
        setIsLoading(true);
        console.log("Fetching clientSecret for amount:", totalAmount);
        fetch(
            "https://us-central1-fitxapp-react-szakdolgozat.cloudfunctions.net/createPaymentIntent",
            {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ amount: Math.round(totalAmount * 100) }), // Az összeg forintban * 100
            }
        )
        .then(res => res.json())
        .then(data => {
            setClientSecret(data.clientSecret);
            console.log("Client secret set:", data.clientSecret); // Naplózzuk a clientSecret beállítását
            setIsLoading(false);
        })
        .catch(error => {
            console.error("Error creating payment intent:", error);
            setIsLoading(false);
        });
    } else {
        console.log("Total amount is not greater than 0");
    }
}, [totalAmount]);


const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitting...", { stripe, clientSecret });
    if (!stripe || !elements || !clientSecret) {
      console.log('Stripe has not loaded yet or missing clientSecret.');
      return;
    }

    setIsLoading(true);
    const cardElement = elements.getElement(CardElement);

    stripe.confirmCardPayment(clientSecret, {
      payment_method: {
          card: cardElement,
          billing_details: {
              name: billingDetails.name,
              email: billingDetails.email,
              address: {
                  line1: billingDetails.address,
                  // További cím mezők itt (line2, city, country, stb.), ha szükséges
              },
              phone: billingDetails.phone,
          },
      },
  }).then((result) => {
      if (result.error) {
          console.error("Fizetési hiba:", result.error.message);
      } else {
          if (result.paymentIntent && result.paymentIntent.status === "succeeded") {
              console.log("Sikeres fizetésl!", result.paymentIntent);
              setSuccessMessage("Köszönjük a vásárlást, 24 órán belül megérkezik a rendelt terv!");
                setTimeout(() => {
                    setSuccessMessage(""); // Törli a sikeres üzenetet
                }, 10000); // Az üzenet eltűnik 10 másodperc után
          } else {
              console.log("A fizetés feldolgozva, státusz sikertelen:", result.paymentIntent);
          }
      }
      setIsLoading(false);
  }).catch((error) => {
      console.error("Hiba a fizetés feldolgozása során:", error);
      setIsLoading(false);
  });
  
};

  // Frissíti a számlázási adatok állapotát
  const handleChange = (event) => {
    setBillingDetails({
      ...billingDetails,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <div>
    {successMessage && <div className="success-popup-checkout">{successMessage}</div>} 
    <form id="payment-form" onSubmit={handleSubmit}>
      <h1>Számlázási adatok</h1>
      <div className="billing-details">
        <input
          type="text"
          name="name"
          placeholder="Név"
          required
          onChange={handleChange}
        />
        <input
          type="email"
          name="email"
          placeholder="Email"
          required
          onChange={handleChange}
        />
        <input
          type="text"
          name="address"
          placeholder="Lakcím"
          required
          onChange={handleChange}
        />
        <input
          type="tel"
          name="phone"
          placeholder="Telefonszám"
          required
          onChange={handleChange}
        />
      </div>
      <CardElement />
      <button disabled={isLoading || !stripe || !elements} id="submit">
        {isLoading ? "Feldolgozás..." : "Fizess most"}
      </button>
    </form>
  </div>
  );
}

export default CheckoutForm;
