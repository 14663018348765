import React, { useState } from 'react';
import './ActivityLevelSelector.css';

const ActivityLevelSelector = ({ onActivityLevelSelect, onBack2 }) => {
  const [selectedActivityLevel, setSelectedActivityLevel] = useState(null);

  const handleActivityLevelSelect = (level) => {
    setSelectedActivityLevel(level); // Az aktuális aktivitási szint állapotba helyezése
    onActivityLevelSelect(level); // A szülő komponensben történő kezelés
  };




  return (
    <div className="activity-level-selector">
      <h2>Válaszd ki az aktivitási szinted</h2>
      <ul>
        <li className={selectedActivityLevel === 'sedentary' ? 'active' : ''} onClick={() => handleActivityLevelSelect('sedentary')}>Enyhe (keveset vagy egyáltalán nem mozog)</li>
        <li className={selectedActivityLevel === 'lightly_active' ? 'active' : ''} onClick={() => handleActivityLevelSelect('lightly_active')}>Enyhén aktív (könnyű testmozgás/sport heti 3-5 nap)</li>
        <li className={selectedActivityLevel === 'moderately_active' ? 'active' : ''} onClick={() => handleActivityLevelSelect('moderately_active')}>Közepesen aktív (mérsékelt testmozgás/sport heti 3-5 nap)</li>
        <li className={selectedActivityLevel === 'very_active' ? 'active' : ''} onClick={() => handleActivityLevelSelect('very_active')}>Nagyon aktív (Kemény edzés/sport heti 6-7 napon)</li>
        <li className={selectedActivityLevel === 'extra_active' ? 'active' : ''} onClick={() => handleActivityLevelSelect('extra_active')}>Extra aktív (Kemény edzés/sport heti 6-7 napon, plusz fizikai munka)</li>
      </ul>
      <button onClick={onBack2}>Vissza</button>
    </div>
  );
};

export default ActivityLevelSelector;
