

// Kalória számítás logikája forrás:https://www.omnicalculator.com/health/bmr-harris-benedict-equation
const calculateCalories = (userChoices) => {
    let BMR;
  
    // Férfiak és nők BMR képletének megkülönböztetése
    if (userChoices.gender === 'male') {
      BMR = 66.5 + (13.75 * userChoices.weight) + (5.003 * userChoices.height) - (6.75 * userChoices.age);
    } else if (userChoices.gender === 'female') {
      BMR = 655.1 + (9.563 * userChoices.weight) + (1.850 * userChoices.height) - (4.676 * userChoices.age);
    } else {
      return null; // Ha nincs megadva nem, akkor nem tudjuk kiszámolni
    }
  
    // Az aktivitási szintek alapján módosítjuk a BMR-t
    const activityMultiplier = {
      'sedentary': 1.2,
      'lightly_active': 1.375,
      'moderately_active': 1.55,
      'very_active': 1.725,
      'extra_active': 1.9
    };
    
    const calories = Math.round(BMR * (activityMultiplier[userChoices.activityLevel] || 1));

    // A célhoz szükséges kalóriabevitel kiszámítása
    const goalCalories = userChoices.subGoal === 'muscleBuilding' 
        ? calories + 500 
        : calories - Math.round(calories * 0.2);

    return { totalDailyCalories: calories, goalCalories };
  };
  
  export default calculateCalories;
  