import React from "react";
import { Link } from "react-router-dom";

const Explore = () => {
  return (
    <section id="explore">
      <div className="container">
        <div className="row row__column">
          <h2>
            Nézd meg az összes <span className="blue">edzéstervet</span> vagy{" "}
            <span className="blue">étrendet</span>
          </h2>
        </div>
        <div className="plans__container">
          <Link to="/plans">
            <br />
            <button className="btn">Edzéstervek</button>
          </Link>
          <Link to="/hero">
            <br />
            <button className="btn">Szabd személyre</button>
          </Link>
          <Link to="/mealplans">
            <br />
            <button className="btn">Étrendek</button>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Explore;
