import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './App.css';
import Header from './components/Hero/Header/Header';
import Hero from './components/Hero/Hero';
import { auth } from "./firebase/init";
import { 
  onAuthStateChanged,
} from "firebase/auth";

import Footer from './components/Footer';
import Home from './pages/Home';
import Plans from "./pages/Plans";
import { workoutPlans } from './data/data';
import { mealPlans } from './data/data';
import PlanInfo from './pages/PlanInfo';
import Cart from './pages/Cart';
import Mealplans from './pages/Mealplans';
import MealPlanInfo from './pages/MealPlanInfo';
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import ForgotPwd from './components/Auth/ForgotPwd';
import Account from './components/Auth/Account';
import WhyChooseUs from './pages/whychooseus';
import CheckoutForm from './components/Auth/CheckoutForm';
import { Switch } from 'react-router-dom/cjs/react-router-dom.min';
import { stripePromise } from './stripe';
import { Elements } from '@stripe/react-stripe-js';
import { useLocation } from "react-router-dom";
import { TotalAmountProvider } from './components/TotalAmountContext';

function App() {
  const [user, setUser] = React.useState({});
  const [cart, setCart] = useState([]);
  const [loading, setLoading] = React.useState(true);
  {
    /*
    Add to cart-ra kattintva többször kosárba lehessen rakni

    const dupeItem = cart.find(item => +item.id === +plan.id)
    if (dupeItem) {
      setCart(cart.map(item => {
       if (item.id === dupeItem.id) {
        return {
          ...item,
          quantity: item.quantity + 1,
        }
       }
       else {
        return item;
       }
      }))
    }
    else {
      setCart([...cart, {...plan, quantity: 1}]);
    }
*/
  }

  function addToCart(plan) {
    setCart([...cart, {...plan, quantity: 1}]);
  }

  function changeQuantity(plan, quantity) {
    setCart(cart.map((item) =>  item.id === plan.id
          ? {
              ...item,
              quantity: +quantity,
            }
          : item          
          )
        )
  }

  function removeItem(item) {
    setCart(cart.filter(plan => plan.id !== item.id))
  }

  function numberOfItems() {
    let counter = 0;
    cart.forEach(item => {
      counter += item.quantity
    })
    return counter;
  }

  function CheckoutWrapper(props) {
    const location = useLocation();
    return (
      <Elements stripe={stripePromise}>
        <CheckoutForm totalAmount={location.state?.totalAmount || 0} />
      </Elements>
    );
  }
  


  useEffect(() => {
    console.log(cart);
  }, [cart])

  React.useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setLoading(false);
      console.log(user);
      if (user) {
        setUser(user)
      }
    })
  }, []);

  return (
    <TotalAmountProvider>
    <Router>
  <div className="App">
    <Header numberOfItems={numberOfItems()} />
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/hero" exact component={Hero} />
      <Route path="/plans" exact render={() => <Plans workoutPlans={workoutPlans} />} />
      <Route path="/plans/:id" render={() => <PlanInfo workoutPlans={workoutPlans} addToCart={addToCart} cart={cart}/>} />
      <Route path="/mealplans" exact render={() => <Mealplans mealPlans={mealPlans} />} />
      <Route path="/mealplans/:id" render={() => <MealPlanInfo mealPlans={mealPlans} addToCart={addToCart} cart={cart}/>} />
      <Route path="/register" exact component={Register} />
      <Route path="/login" exact component={Login} />
      <Route path="/forgotPwd" exact component={ForgotPwd} />
      <Route path="/account" exact component={Account} />
      <Route path="/whychooseus" exact component={WhyChooseUs} />
      <Route path="/cart" render={() => (  
      <Cart 
      workoutPlans={workoutPlans} 
      cart={cart} 
      changeQuantity={changeQuantity} 
      removeItem = {removeItem} 
      />
      )} 
      />
      <Route path="/checkout" component={CheckoutWrapper} />
    </Switch>
    <Footer />
  </div>
</Router>
</TotalAmountProvider>
  );
}

export default App;
