import React from "react";
import Planpic from "../assets/whyus/planning.svg";
import Expert from "../assets/whyus/expert.svg";
import Support from "../assets/whyus/support.svg";

const WhyChooseUs = () => {
  return (
    <div className="why-choose-us">
      <div className="why-choose-us-top">
        <h1>Tarts velünk!</h1>
        <h2>Nálunk garantált a fejlődés!</h2>
      </div>
      <div className="content">
        <section> 
          <div>
          <h2>Egyedi Étrend- és Edzéstervek</h2>
          <p>
            Célunk, hogy személyre szabott, teljesen egyedi étrend- és
            edzésterveket kínáljunk. Minden tervet az Ön céljaihoz,
            testalkatához és életmódjához igazítunk, hogy a lehető legjobb
            eredményeket érje el.
          </p>
          </div>
          <img src={Planpic} alt="" />
        </section>
        
        <section>
          <div>
          <h2>Tapasztalt Szakértők</h2>
          <p>
            Professzionális csapatunk évek óta a fitness és táplálkozástudomány
            területén dolgozik. Szakértőink segítenek Önnek abban, hogy
            egészséges, kiegyensúlyozott módon érje el fitnesz céljait.
          </p>
          </div>
          <img src={Expert} alt="" />
        </section>
        <section>
          <div>
          <h2>Állandó Támogatás</h2>
          <p>
            Bármilyen kérdése vagy aggodalma van, szakértő csapatunk mindig
            rendelkezésére áll. Támogatjuk Önt az út minden lépésénél, hogy
            biztosak lehessünk abban, hogy elégedett lesz az eredményekkel.
          </p>
          </div>
          <img src={Support} alt="" />
        </section>
      </div>
    </div>
  );
};

export default WhyChooseUs;
