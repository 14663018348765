import React from 'react'
import Training from '../assets/training.svg'
import { Link } from 'react-router-dom';

const Landing = () => {
  return (
    <section id="landing">
        <header>
            <div className="header__container">
                <div className="header__description">
                    <h1>Hozd ki a <span className="blue">maximumot</span> magadból</h1>
                    <h2> Használd <span className="blue">kalória kalkulátorunkat,</span> a céltudatos élethez!</h2>
                    <br />
                    <Link to="/hero">
                        <button className="btn">Kalória kalkulátor</button>
                    </Link>
                </div>
                <figure className='header__img--wrapper'>
                    <img src={Training} alt="" />
                </figure>
            </div>
        </header>
    </section>
  )
}


export default Landing;