import React, { useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../firebase/init";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ForgotPwd = ({ onShowLogin }) => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    setError("");
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage("E-mailt küldtünk a jelszó visszaállításához.");
    } catch (error) {
      setError("Hiba történt. Kérjük, ellenőrizze az e-mail címet.");
    }
  };

  return (
    <div className="userauth-form-container">
        <div className="row__container">
        <button onClick={onShowLogin} className="row__btn">
          <FontAwesomeIcon icon="arrow-left" />
        </button>
      <h1>Elfelejtetted a jelszavad?</h1>
        </div>
      <br />
      <h3 className="h3__message">
        Ne aggódj, küldeni fogunk egy üzenetet, amiben segítünk neked
        visszaállítani a jelszavad.
      </h3>
      <form onSubmit={handleSubmit} className="userauth-form">
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email cím"
          className="userauth-input"
        />
        <button type="submit" className="userauth-button">
          Jelszó visszaállítása
        </button>
        {message && <p className="userauth-message">{message}</p>}
        {error && <p className="userauth-error">{error}</p>}
      </form>
    </div>
  );
};

export default ForgotPwd;
