import React from 'react'

const Price = ({ salePrice, originalPrice }) => {
  // Formátumozó létrehozása magyar forintokhoz
  const formatter = new Intl.NumberFormat('hu-HU', {
    style: 'currency',
    currency: 'HUF',
    minimumFractionDigits: 0 // Nincsenek tizedesek
  });

  return (
    <div className="plan__price">
      {salePrice ? (
        <>
          <span className="plan__price--normal">
            {formatter.format(originalPrice)}
          </span>
          {formatter.format(salePrice)}
        </>
      ) : (
        <>{formatter.format(originalPrice)}</>
      )}
    </div>
  )
}

export default Price
