import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { React, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Rating from "../components/ui/Rating";
import Price from "../components/ui/Price";
import MealPlan from "../components/ui/Plan";

const MealPlanInfo = ({ mealPlans, addToCart, cart }) => {
  const { id } = useParams();
  /* +-jel numberré konvertálja */
  const plan = mealPlans.find((plan) => +plan.id === +id);

  function addPlanToCart(plan) {
    addToCart(plan);
  }

  function planExistsOnCart() {
    const dupe = cart.find((plan) => plan.id === +id);
    return dupe;
  }

  return (
    <div id="plans__body">
      <main id="plans__main">
        <div className="plans__container">
          <div className="row">
            <div className="plan__selected--top">
              <Link to="/mealplans" className="plan__link">
                <FontAwesomeIcon icon="arrow-left" />
              </Link>
              <Link to="/mealplans" className="plan__link">
                <h2 className="plan__selected--title--top">Étrendek</h2>
              </Link>
            </div>
            <div className="plan__selected">
              <figure className="plan__selected--figure">
                <img src={plan.url} alt="" className="plan__selected--img" />
              </figure>
              <div className="plan__selected--description">
                <h2 className="plan__selected--title">{plan.title}</h2>
                <Rating rating={plan.rating} />
                <div className="plan__selected--price">
                  <Price
                    originalPrice={plan.originalPrice}
                    salePrice={plan.salePrice}
                  />
                </div>
                <div className="plan__summary">
                  <h3 className="plan__summary--title">Összefoglalás</h3>
                  <p className="plan__summary--para">
                    A tudatos étkezés alapvető lépés az egészséges élet felé.
                    Személyre szabott étrendjeinkkel a megvásárlás után kapott
                    részletes leírás segítségével könnyen követheted a helyes
                    táplálkozást. Válts egészséges életmódra velünk, és élvezd
                    annak minden előnyét!
                  </p>
                  <p className="plan__summary--para">
                    A személyre szabott étrendek segítenek elérni egészségi és
                    fizikai céljaidat, táplálkozási szokásaidra szabva.
                    Hatékonyan támogatnak a helyes tápanyag-bevitelben, javítva
                    közérzetedet és hosszú távú egészségedet. A tudatos étkezés
                    és egyéni étrendjeinkkel könnyebben tarthatod meg
                    testi-lelki egyensúlyod és céljaidat.
                  </p>
                </div>
                {planExistsOnCart() ? (
                  <Link to={`/cart`} className="plan__link">
                    <button className="btn">Pénztár</button>
                  </Link>
                ) : (
                  <button className="btn" onClick={() => addPlanToCart(plan)}>
                    Kosárba
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="plans__container">
          <div className="row">
            <div className="plan__selected--top">
              <h2 className="plan__selected--title--top">Ajánlott étrendek</h2>
            </div>
            <div className="plans">
              {mealPlans
                .filter((plan) => plan.rating === 5 && +plan.id !== +id)
                .slice(0, 4)
                .map((plan) => (
                  <MealPlan plan={plan} key={plan.id} />
                ))}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default MealPlanInfo;
