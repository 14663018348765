import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars, faShoppingCart, faTimes, faBolt, faTags, faDumbbell, faStar, faStarHalfAlt, faArrowLeft, faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons';

library.add(faBars, faShoppingCart, faTimes, faDumbbell, faBolt, faTags, faStar, faStarHalfAlt, faArrowLeft, faSignOutAlt, faUser)


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals