import React from 'react'
import { workoutPlans } from '../data/data'
import Plan from './ui/Plan'

const Discounted = () => {
  return (
    <section id="recent">
        <div className="container">
            <div className="row">
                <h2 className="section__title">
                   <span className='black'> Akciós </span> <span className="blue">Edzéstervek</span>
                </h2>
                <div className="plans">
                    {workoutPlans
                    .filter(plan => plan.salePrice > 0)
                    .slice(0,8)
                    .map((plan) => (
                         <Plan plan={plan} key={plan.id} />
                    ))}
                </div>
            </div>
        </div>
    </section>
  )
}

export default Discounted