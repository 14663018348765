import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase/init";
import Modal from "./Modal"; // Feltételezve, hogy a Modal komponens elérhető

const ReauthenticateModal = ({ email, onClose, onReauthenticated }) => {
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleReauthenticate = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      onReauthenticated(password);
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <Modal isOpen={true} onClose={onClose}>
      <div className="modal-container">
        <div className="modal-image"></div>
        <div className="modal-content">
            <div className="verify__title--container">
            <h1>Biztosan te vagy?</h1>
            <br />
            <h3 className="grey">A biztonságod érdekében szeretnénk megbizonyosodni arról, hogy biztosan te vagy az.</h3>
            </div>
          <form onSubmit={handleReauthenticate} className="userauth-form">
            <h3 className="smaller__h3">Email</h3>
            <b className="userauth__input--readonly">{email}</b>
            <h3 className="smaller__h3">Jelszó</h3>
            <br />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Jelszó"
              className="userauth-input"
            />
            <button type="submit">Megerősítés</button>
            {error && <p className="userauth-error">{error}</p>}
          </form>
          <button onClick={onClose} className="btn__cancel">
            Mégse
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ReauthenticateModal;
