import React from 'react';
import './UserDetailsForm.css';


const UserDetailsForm = ({ userChoices, setUserChoices, onSubmit, onBack  }) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserChoices({ ...userChoices, [name]: value });
  };



  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(); // Meghívja a Hero komponensből átadott callback függvényt
  };

  return (
    <form className="user-details-form" onSubmit={handleSubmit}>
      <input
        type="number"
        name="age"
        value={userChoices.age || ''}
        onChange={handleInputChange}
        placeholder="Kor"
        min="1"
        max="110"
      />
      <input
        type="number"
        name="height"
        value={userChoices.height || ''}
        onChange={handleInputChange}
        placeholder="Magasság"
        min="0"
        max="250"
      />
      <input
        type="number"
        name="weight"
        value={userChoices.weight || ''}
        onChange={handleInputChange}
        placeholder="Súly"
        min="0"
        max="250"
      />
      <button type="submit">Tovább</button>
      <button type="button" onClick={onBack}>Vissza</button>
    </form>
  );
};

export default UserDetailsForm;
