import React from "react";
import EmptyCart from "../assets/empty_cart.svg"
import { Link } from 'react-router-dom';
import { useHistory  } from 'react-router-dom';
import { useTotalAmount } from '../components/TotalAmountContext';

const Cart = ({  cart, changeQuantity, removeItem }) => {
    const formatter = new Intl.NumberFormat('hu-HU', {
        style: 'currency',
        currency: 'HUF',
        minimumFractionDigits: 0 // Nincsenek tizedesek
      });

    // Nyers összeg kiszámítása
    const calculateTotal = () => {
        let price = 0;
        cart.forEach((item) => {
            price += ((item.salePrice || item.originalPrice) * item.quantity);
        });
        return price;
    }

    // Formázott összeg
    const total = formatter.format(calculateTotal());

    // Subtotal és tax számítása
    const subtotal = formatter.format(calculateTotal() * 0.73);
    const tax = formatter.format(calculateTotal() * 0.27);
     
    const history = useHistory();
    const { setTotalAmount } = useTotalAmount();

    const handleCheckout = () => {
        const amount = calculateTotal();
        setTotalAmount(amount); // Beállítja az összeget a kontextusban
        // Átirányítás a checkout oldalra
        history.push('/checkout');
      };

      

    
  return (
    <div id="plans__body">
        <main id="plans__main">
            <div className="plans__container">
                <div className="row">
                    <div className="plan__selected--top">
                        <h2 className="cart__title">
                            Kosár
                        </h2>
                    </div>
                    <div className="cart">
                        <div className="cart__header">
                            <span className="cart__plan">Edzésterv</span>
                            <span className="cart__quantity">Mennyiség</span>
                            <span className="cart__total">Ár</span>
                        </div>
                        <div className="cart__body">
                            {
                                cart.map((plan) => {
                                    return (
                                        <div className="cart__item">
                                        <div className="cart__plan">
                                            <img src={plan.url} className='cart__plan--img' alt="" />
                                            <div className="cart__plan--info">
                                                <span className="cart__plan--title">
                                                    {plan.title}
                                                </span>
                                                <span className="cart__plan--price">
                                                {formatter.format(plan.salePrice || plan.originalPrice)} 
                                                </span>
                                                <button className="cart__plan--remove" onClick={() => removeItem(plan)}>
                                                    Törlés
                                                </button>
                                            </div>
                                        </div>
                                        <div className="cart__quantity">
                                            <input type="number" 
                                            min={0} 
                                            max={99} 
                                            className='cart__input'
                                            value={plan.quantity}
                                            onChange={(event) => changeQuantity(plan,  event.target.value)}
                                            />
                                        </div>
                                        <div className="cart__total">
                                            {formatter.format((plan.salePrice || plan.originalPrice) * plan.quantity)} 
                                        </div>
                                    </div>
                                    )
                                })}
                        </div>
                        {cart.length === 0 && (    
                            <div className="cart__empty">                    
                                <img src={EmptyCart} alt="" className="cart__empty--img" />
                                <h2>A kosarad jelenleg üres!</h2>
                                <br />
                                <div className="plans__container--cart">
                                <Link to="/plans">
                                    <button className="btn">Keress edzésterveket</button> 
                                </Link> 
                                <br />                          
                                <Link to="/mealplans">
                                    <button className="btn">Keress étrendeket</button>
                                </Link>                           
                                </div>
                        </div>
                        )}
                    </div>
                    {cart.length > 0 && (
                    <div className="total">
                        <div className="total__item total__subtotal">
                            <span>Részösszeg</span>
                            <span>{subtotal}</span>
                        </div>
                        <div className="total__item total__tax">
                            <span>Áfa</span>
                            <span>{tax}</span>
                        </div>
                        <div className="total__item total__price">
                            <span>Teljes összeg</span>
                            <span>{total}</span>
                        </div>
                        <Link to="/checkout" >
                        <button className="btn btn__checkout"
                        onClick={handleCheckout}>
                            Tovább a fizetéshez
                        </button>
                        </Link>
                    </div>
                    )}
                </div>
            </div>
        </main>
    </div>
  )
}

export default Cart