import React, { useState } from "react";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { auth } from "../../firebase/init";

const Register = ({ onShowLogin }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isRegistrationSuccessful, setRegistrationSuccessful] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Felhasználói profil frissítése a névvel
      await updateProfile(user, {
        displayName: name,
      });

      setRegistrationSuccessful(true);
      setTimeout(() => {
        setRegistrationSuccessful(false);
        onShowLogin();
      }, 3000);
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="userauth-form-container">
      <h1>Regisztráció</h1>
      {isRegistrationSuccessful && (
        <p className="userauth-success">Sikeres regisztráció!</p>
      )}
      <form onSubmit={handleSubmit} className="userauth-form">
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Név"
          className="userauth-input"
        />
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email cím"
          className="userauth-input"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Jelszó"
          className="userauth-input"
        />
        <button type="submit" className="userauth-button">
          Regisztráció
        </button>
        {error && <p className="userauth-error">{error}</p>}
        <div className="userauth-links">
          <button className="userauth-link" onClick={onShowLogin}>
            <span className="black">Van már fiókod?</span> <span className="underlined">Jelentkezz be!</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default Register;
