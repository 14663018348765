import React, { useState, useEffect } from "react";
import { auth } from "../../firebase/init";
import { updateProfile, updatePassword } from "firebase/auth";
import ReauthenticateModal from "./ReauthenticateModal";

const Account = () => {
  const user = auth.currentUser;
  const [isEditingName, setIsEditingName] = useState(false);
  const [isEditingPassword, setIsEditingPassword] = useState(false);
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showReauthenticateModal, setShowReauthenticateModal] = useState(false);
  const [passwordChangeAllowed, setPasswordChangeAllowed] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(true);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const saveName = async () => {
    try {
      await updateProfile(user, { displayName: name });
      setIsEditingName(false);
      setSuccessMessage("Név sikeresen frissítve.");
    } catch (error) {
      setError(error.message);
    }
  };

  const onReauthenticated = async (newPassword) => {
    setPasswordChangeAllowed(true); // Engedélyezzük a jelszó módosítását
    setShowReauthenticateModal(false);
  };

  const savePassword = async () => {
    if (!passwordChangeAllowed) return;
    try {
      // Itt frissítjük a felhasználó jelszavát
      await updatePassword(user, password);
      setPasswordChangeAllowed(false); // Visszaállítjuk az állapotot
      setPassword(""); // Töröljük a jelszó mezőt a sikeres frissítés után
      setSuccessMessage("Jelszó sikeresen frissítve.");
    } catch (error) {
      setError(error.message);
    }
  };

  // Jelszó erősségének számítása
  const calculatePasswordStrength = (password) => {
    let strength = 0;
    if (password.length > 5) strength += 1;
    if (password.length > 10) strength += 1;
    if (/[A-Z]/.test(password)) strength += 1;
    if (/[0-9]/.test(password)) strength += 1;
    if (/[^A-Za-z0-9]/.test(password)) strength += 1;
    return strength;
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setPasswordStrength(calculatePasswordStrength(newPassword));
  };

  // Jelszó erősségétől függő szín beállítása
const getPasswordStrengthColor = (strength) => {
  switch (strength) {
    case 1:
    case 2:
      return 'red';
    case 3:
      return 'orange';
    case 4:
    case 5:
      return 'green';
    default:
      return 'transparent';
  }
};

  useEffect(() => {
    if (user) {
      setName(user.displayName || "");
    }
  }, [user]);

  useEffect(() => {
    // Ha a Firebase-ből töltődnek be az adatok
    const fetchUserData = async () => {
      try {
        // Itt történik a felhasználói adatok lekérése a Firebase-ből
        // ...
        setLoading(false); // Adatok betöltődése után állítsd false-ra
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);


  if (loading) {
    return (
      <div className="account__container">
        <div className="skeleton account__skeleton--title"></div>
        <div className="skeleton account__skeleton--detail"></div>
      </div>
    );
  }


  return (
    <div>
      {showReauthenticateModal && (
        <ReauthenticateModal
          email={user.email}
          onClose={() => setShowReauthenticateModal(false)}
          onReauthenticated={onReauthenticated}
        />
      )}
      {successMessage && (
        <div className="success-popup">
          {successMessage}
          <button onClick={() => setSuccessMessage("")} className="btn__success">Bezárás</button>
        </div>
      )}
      <div className="account__container">
        <h1 className="account__title border__bottom">Fiók adatok</h1>
        <div className="">
          {isEditingName ? (
            <div className="detail border__bottom">
              <div className="detail__field">
                <div className="field__grid">
                  <div class="detail__title">Név</div>

                  <div className="detail__input el-input">
                    <input
                      type="text"
                      value={name}
                      onChange={handleNameChange}
                      className="el-input__inner"
                    />
                  </div>
                </div>
              </div>
              <div className="btns__detail">
                <button onClick={saveName} className="btn__modify">
                  Mentés
                </button>
                <button
                  onClick={() => setIsEditingName(false)}
                  className="btn__cancel"
                >
                  Mégse
                </button>
              </div>
            </div>
          ) : (
            <div className="detail border__bottom">
              <div className="detail__field">
                <div className="field__grid">
                  <div className="detail__title">Név</div>
                  <div className="detail__value">
                    {user && user.displayName
                      ? user.displayName
                      : "Nincs megadva"}
                  </div>
                </div>
              </div>
              <button
                onClick={() => setIsEditingName(true)}
                className="btn__edit btn__detail"
              >
                Szerkesztés
              </button>
            </div>
          )}
        </div>

        <div className="detail border__bottom">
          <div className="detail__field">
            <div className="field__grid">
              <div className="detail__title">Email</div>
              <div className="detail__value">
                {user ? user.email : "Nincs email cím"}
              </div>
            </div>
          </div>
        </div>

        <div>
          {passwordChangeAllowed ? (
            <div className="detail border__bottom">
              <div className="detail__field">
                <div className="field__grid">
                  <div class="detail__title">Jelszó</div>
                  <div className="detail__filed--pw field__grid">
                    <div className="detail__input el-input el-input--suffix">
                      <input
                        type="password"
                        autoComplete="off"
                        placeholder="Jelszó"
                        value={password}
                        onChange={handlePasswordChange}
                        className="el-input__inner"
                      />
                      <span className="el-input__suffix">
                        <span className="el-input__suffix-inner"></span>
                      </span>
                    </div>
                    <div className="detail__pw-strength">
                      <div className="Password">
                        <div className="Password__strength-meter">
                          <div className="Password__strength-meter--fill" style={{ width: `${passwordStrength * 20}%`, background: getPasswordStrengthColor(passwordStrength) }}></div>
                        </div>
                      </div>
                      <div className="form__text">
                        <p className="grey">
                          <b></b>
                          Használjon egyedi jelszót betűk, számok és szimbólumok keverékével.
                        </p>
                      </div>
                    </div>
                    <div className="btns__detail">
                      <button onClick={savePassword} className="btn__modify">
                        Mentés
                      </button>
                      <button
                        onClick={() => setPasswordChangeAllowed(false)}
                        className="btn__cancel"
                      >
                        Mégse
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="detail border__bottom">
              <div className="detail__field">
                <div className="field__grid">
                  <div className="detail__title">Jelszó</div>
                  <div className="detail__value">
                    Változtasd meg a jelszavad itt
                  </div>
                </div>
              </div>
              <button
                onClick={() => setShowReauthenticateModal(true)}
                className="btn__edit btn__detail"
              >
                Változtatás
              </button>
            </div>
          )}
        </div>
      </div>
      {error && <p className="error">{error}</p>}
    </div>
  );
};


export default Account;
