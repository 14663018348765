// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBbF7Kzp0DJj9bhDjPrFET4P9Cy7EVaLM0",
  authDomain: "fitxapp-react-szakdolgozat.firebaseapp.com",
  projectId: "fitxapp-react-szakdolgozat",
  storageBucket: "fitxapp-react-szakdolgozat.appspot.com",
  messagingSenderId: "16953305341",
  appId: "1:16953305341:web:6fa0340c39411bc9eef694",
  measurementId: "G-022ZR31GEP"
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth();