import React, { useState } from 'react'
import Mealplan from '../components/ui/Mealplan'


const Mealplans = ({ mealPlans: initialmealPlans }) => {
    const [mealPlans, setmealPlans] = useState(initialmealPlans);

    function filtermealPlans(filter) {
        /*Uj array-en atmegyunk, hogy ez lehetseges legyen klonozzuk a regit, rendezzuk*/
        console.log(filter)
        if (filter === "LOW_TO_HIGH") {
            setmealPlans(mealPlans.slice().sort((a, b) => (a.salePrice || a.originalPrice) - (b.salePrice || b.originalPrice)))
        }
        if (filter === "HIGH_TO_LOW") {
            setmealPlans(mealPlans.slice().sort((a, b) => (b.salePrice || b.originalPrice) - (a.salePrice || a.originalPrice)))

        }
        if (filter === "RATING") {
            setmealPlans(mealPlans.slice().sort((a, b) => (b.rating) - (a.rating)))
        }
    }
  return (
    <div id="plans__body">
        <main id="plans__main">
            <section>
                <div className="plans__container">
                    <div className="row">
                        <div className="plans__header">
                            <h2 className='section__title plans__header--title' >Minden étrend</h2>
                            <select id="filter" defaultValue="DEFAULT" onChange={(event) => filtermealPlans(event.target.value)}>
                                <option value="DEFAULT" disabled>Rendezés</option>
                                <option value="LOW_TO_HIGH">Ár növekvő</option>
                                <option value="HIGH_TO_LOW">Ár csökkenő</option>
                                <option value="RATING">Értékelés</option>
                            </select>
                        </div>
                        <div className="plans">
                            {mealPlans.map((plan) => (
                                <Mealplan plan={plan} key={plan.id} />
                                ))}
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </div>
  )
}

export default Mealplans