import React, { useState, useEffect } from "react";
import "./Header.css";
import Logo from "../../../assets/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { auth } from "../../../firebase/init";
import { onAuthStateChanged, signOut } from "firebase/auth";
import Modal from "../../Auth/Modal";
import Register from "../../Auth/Register";
import Login from "../../Auth/Login";
import ForgotPwd from "../../Auth/ForgotPwd";

const Header = ({ logoClick, numberOfItems }) => {
  const [isScrolled, setScrolled] = useState(false);
  const [user, setUser] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("login");
  // Email cím első betűjének meghatározása
  const userInitial = user?.email ? user.email.charAt(0).toUpperCase() : "";

  const openModal = (content) => {
    setIsModalOpen(true);
    setModalContent(content);
  };

  const handleShowForgotPwd = () => {
    setIsModalOpen(true);
    setModalContent("forgotPwd");
  };

  const handleShowRegister = () => {
    setIsModalOpen(true);
    setModalContent("register");
  };

  const handleShowLogin = () => {
    setIsModalOpen(true);
    setModalContent("login");
  };

  const closeAndRefresh = () => {
    setIsModalOpen(false);
    // itt lehet frissíteni a felhasználói adatokat,
  };

  const goToAccount = () => {
    window.location = '/account';
  };

  function openMenu() {
    document.body.classList += " menu--open";
  }

  function closeMenu() {
    document.body.classList.remove("menu--open");
  }

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    // Eseményfigyelő hozzáadása
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
  }, []);

  const handleLogout = () => {
    signOut(auth);
  };

  return (
    <nav>
      <div className="nav__container">
        <Link to="/" onClick={logoClick}>
          <img className="logo" src={Logo} alt="FitX Logo" />
        </Link>
        <ul className="header-menu">
          <li className="nav__list">
            <Link to="/plans" className="nav__link">
              Edzéstervek
            </Link>
          </li>
          <li className="nav__list">
            <Link to="/mealplans" className="nav__link">
              Étrendek
            </Link>
          </li>
          <li className="nav__list">
            <Link to="/whychooseus" className="nav__link">
              Miért minket válassz
            </Link>
          </li>

          <li className="nav__list dropdown">
            {user ? (
              <button className="user-icon dropbtn">{userInitial}</button>
            ) : (
              <button className="dropbtn">Fiókom</button>
            )}
            <div className="dropdown-content">
              {!user ? (
                <div className="drp__container">
                  <button onClick={() => openModal("login")} className="ubtn">
                      Bejelentkezés
                    </button>
                    <button
                      onClick={() => openModal("register")}
                      className="ubtn"
                    >
                      Regisztráció
                    </button>
                </div>
              ) : (
                <div className="dropdown-content">
                  <button onClick={goToAccount} className="ubtn"><FontAwesomeIcon icon="user" className="smaller__icon"/>Fiókom</button>
                  <button onClick={handleLogout} className="ubtn"><FontAwesomeIcon icon="sign-out-alt" className="smaller__icon"/> Kijelentkezés</button>
                </div>
              )}
            </div>
          </li>

          <button className="btn__menu" onClick={openMenu}>
            <FontAwesomeIcon icon="bars" />
          </button>
          <li className="nav__icon">
            <Link to="/cart" className="nav__link">
              <FontAwesomeIcon icon="shopping-cart" />
            </Link>
            {numberOfItems > 0 && (
              <span className="cart__length">{numberOfItems}</span>
            )}
          </li>
        </ul>
        <div className="menu__backdrop">
          <button className="btn__menu btn__menu--close" onClick={closeMenu}>
            <FontAwesomeIcon icon="times" />
          </button>
          <ul className="menu__links">
            <li className="menu__list" onClick={closeMenu}>
              <Link to="/" className="menu__link">
                Kezdőlap
              </Link>
            </li>
            <li className="menu__list" onClick={closeMenu}>
              <Link to="/plans" className="menu__link">
                Edzéstervek
              </Link>
            </li>
            <li className="menu__list" onClick={closeMenu}>
              <Link to="/mealplans" className="menu__link">
                Étrendek
              </Link>
            </li>
            <li className="menu__list" onClick={closeMenu}>
              <Link to="/whychooseus" className="menu__link">
                Miért minket válassz
              </Link>
            </li>
          </ul>
           
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className="modal-container">
          <div className="modal-image"></div>
          <div className="modal-content">
            {modalContent === "login" && (
              <Login
                onShowRegister={handleShowRegister}
                onShowForgotPwd={handleShowForgotPwd}
                onLoginSuccess={closeAndRefresh}
              />
            )}
            {modalContent === "register" && (
              <Register onShowLogin={handleShowLogin} />
            )}
            {modalContent === "forgotPwd" && (
              <ForgotPwd onShowLogin={handleShowLogin} />
            )}
          </div>
        </div>
      </Modal>
    </nav>
  );
};

export default Header;
