import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const Rating = ({ rating }) => {
  return (
    <div className="plan__ratings">
        {new Array(Math.floor(rating)).fill(0).map((_, index) => (
        <FontAwesomeIcon icon="star" key={index} />
        ))}
        {/**Ha nem Integer akkor prinetli 4,5 csillagosnak */
        !Number.isInteger(rating) && (
        <FontAwesomeIcon icon="star-half-alt"/>
        )}
    </div>
  )
}

export default Rating