import React from 'react'
import Logo from "../assets/logo.png"
import { Link } from 'react-router-dom';


const Footer = () => {
  return (
    <footer>
        <div className="container__footer">
            <div className="row row__column">
                <Link to="">
                    <figure className="footer__logo">
                        <img src={Logo} alt="" className="footer__logo--img" />
                    </figure>
                </Link>
                <div className="footer__list">
                    <Link to="/" className='footer__link'>Kezdőlap</Link>
                    <span className='footer__link no-cursor'>Rólunk</span>
                    <Link to="/plans" className='footer__link'>Edzéstervek</Link>
                    <Link to="/mealplans" className='footer__link'>Étrendek</Link>
                    {/*<Link to="/aszf" className='footer__link'>ÁSZF</Link>
                    <Link to="/adatkezeles" className='footer__link'>Adatkezelési tájékoztató</Link>
                     <Link to="/cookies" className='footer__link'>Cookie-nyilatkozat</Link> */}
                </div>
                <div className="footer__copyright">
                    Minden jog fenntartva &copy; 2024 fitxplan.hu
                </div>
            </div>
        </div>
    </footer>
  )
}

export default Footer