import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase/init";

const Login = ({ onShowRegister, onShowForgotPwd, onLoginSuccess }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await signInWithEmailAndPassword(auth, email, password);
      if (onLoginSuccess) {
        onLoginSuccess(); 
      }
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="userauth-form-container">
      <h1>Jelentkezz be a fiókodba</h1>
      <br />
      <form onSubmit={handleSubmit} className="userauth-form">
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email cím"
          className="userauth-input"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Jelszó"
          className="userauth-input"
        />
        <button type="submit" className="userauth-button">
          Bejelentkezés
        </button>
        {error && <p className="userauth-error">{error}</p>}
        <div className="userauth-links">
          {/* Itt jeleníti meg a modális ablakot az elfelejtett jelszóval kapcsolatosan */}
          <button className="userauth-link" onClick={onShowForgotPwd}>
            <span className="black">Elfelejtetted a </span>{" "}
            <span className="underlined">jelszavad?</span>
          </button>
          <br />
          {/* Itt vált a regisztrációs modálisra */}
          <button className="userauth-link" onClick={onShowRegister}>
            Új vagy itt? Regisztrálj!
          </button>
        </div>
      </form>
    </div>
  );
};

export default Login;
